/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { NumberInput } from "@spotoninc/nexus-react";
import clsx from "clsx";
import { useField } from "formik";

import { INumberFieldProps } from "./NumberField.types";

import styles from "./NumberField.module.scss";

export function NumberField<T>(props: INumberFieldProps<T>) {
    const {
        name,
        label,
        className,
        formik: { handleBlur, setFieldValue },
        value,
        onChange = () => ({}),
        onBlur = () => ({}),
        onFocus = () => ({}),
        isDisabled,
        placeholder,
        id,
        errorMessage,
        allowNegative,
        allowLeadingZeros = false,
        decimalScale,
        minLength,
        maxLength,
        prefix,
        suffix,
        hideErrorMessage,
        parseToFloat = true,
        allowedDecimalSeparators = [".", ","],
    } = props;

    const [field, meta] = useField(name);

    const onFocusField = (e: React.SyntheticEvent<Element, Event>) => {
        onFocus(e);
    };

    const onBlurField = (e: React.SyntheticEvent<Element, Event>) => {
        handleBlur(e);
        onBlur(e);
    };

    /**
     *  Clearable button does not trigger onChange which means form value is not updated.
     *  It triggers onValueChange so we need to set the form value by ourselves.
     *  Hopefully, it is just a temporary solution as all the other
     *  fields from spoton-lib do trigger onChange on clear.
     * */
    const onValueChangeField = async (
        event: React.SyntheticEvent<HTMLInputElement> | undefined,
        values: {
            floatValue: number | undefined;
            formattedValue: string;
            value: string;
        },
    ) => {
        if (parseToFloat) {
            const newValue = values.formattedValue ? values.floatValue : "";
            await setFieldValue(name, newValue);
        } else {
            await setFieldValue(name, values.formattedValue);
        }
        onChange(event);
    };

    return (
        <NumberInput
            {...field}
            id={id || `${name}-field`}
            value={value}
            allowedDecimalSeparators={allowedDecimalSeparators}
            onChange={onValueChangeField}
            onBlur={onBlurField}
            onFocus={onFocusField}
            className={clsx(className, styles.NumberField, "text-left")}
            label={label}
            suffix={suffix || ""}
            errorMessage={
                hideErrorMessage && meta.error
                    ? " "
                    : meta.touched
                    ? errorMessage ?? meta.error
                    : ""
            }
            disabled={isDisabled}
            placeholder={placeholder}
            allowNegative={allowNegative}
            allowLeadingZeros={allowLeadingZeros}
            decimalScale={decimalScale}
            minLength={minLength}
            maxLength={maxLength}
            prefix={prefix || ""}
            data-testid={id || name}
        />
    );
}

export default NumberField;
