import React, { ReactNode, useState } from "react";
import {
    BodyText,
    GhostButton,
    HeadingText,
    Modal,
    ModalBody,
    ModalHeader,
} from "@spotoninc/nexus-react";
import clsx from "clsx";
import { Divider } from "spoton-lib";

import { IPageComponentProps } from "app/types";
import { Breakpoint, useBreakpoint } from "features/hooks";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

type SectionItems = { label: ReactNode; value: ReactNode }[];
const Section = ({ title, items }: { title: string; items: SectionItems }) => {
    return (
        <div>
            <div className="py-4">
                <h3 className="text-base font-semibold">{title}</h3>
            </div>
            <ul>
                {items.map(({ label, value }, i, dt) => (
                    <>
                        <li className="flex w-full justify-between py-4 items-start">
                            <BodyText className="text-neutral-60 !font-semibold !text-sm tablet:!text-base">
                                {label}
                            </BodyText>
                            <span className="whitespace-nowrap min-w-[110px] tablet:min-w-[180px] text-right text-sm tablet:text-base">
                                {value}
                            </span>
                        </li>
                        {i !== dt.length - 1 && <Divider />}
                    </>
                ))}
            </ul>
        </div>
    );
};

const LabelWithDetails = ({
    label,
    details,
    onClick,
}: {
    label: ReactNode;
    details?: string;
    onClick?: () => void;
}) => {
    const { t } = useTranslation();
    return (
        <span>
            <span className="pr-3">{label}</span>
            <GhostButton as="a" onClick={onClick}>
                {details ?? t("detailsText")}
            </GhostButton>
        </span>
    );
};

export const PlatformView = ({
    appStore,
}: IPageComponentProps & {
    children?: ReactNode;
}) => {
    const { t } = useTranslation();
    const [getState] = useDerivedState(
        appStore,
        ({
            data: {
                platformStep,
                config: { isPayments },
            },
        }) => ({
            platformStep,
            isPayments,
        }),
    );
    const { platformStep, isPayments } = getState();

    const isLowerThanTablet = useBreakpoint(Breakpoint.LowerThanTablet);

    const [isDetailsModalOpen, setIsDetailsModalOpen] =
        useState<boolean>(false);

    return (
        <div className="flex flex-col gap-8 pt-5">
            <Modal
                isOpen={isDetailsModalOpen}
                takeOverModal={isLowerThanTablet}
                className={clsx({
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    "w-full max-w-[unset] h-[calc(100dvh-2.75rem)] absolute top-[2.75rem] flex flex-col":
                        !isLowerThanTablet,
                })}
            >
                <ModalHeader
                    onClose={() => {
                        setIsDetailsModalOpen(false);
                    }}
                >
                    {t("quoteDetails")}
                </ModalHeader>
                <ModalBody className="h-full">
                    <div className="flex h-full w-full justify-center items-center">
                        No data yet
                    </div>
                </ModalBody>
            </Modal>
            <div className="flex flex-col text-left gap-4">
                <div className="w-full flex justify-between items-center">
                    <HeadingText size="small">
                        {t("hardwareAndSoftware")}
                    </HeadingText>
                    <GhostButton onClick={() => setIsDetailsModalOpen(true)}>
                        {t("viewDetails")}
                    </GhostButton>
                </div>
                <Section
                    title={t("proposalDetails")}
                    items={[
                        {
                            label: t("paymentPlan"),
                            value: "20-month installments",
                        },
                        { label: t("shippingPlan"), value: "Expedited" },
                        {
                            label: (
                                <LabelWithDetails label={t("hardwareTotal")} />
                            ),
                            value: "$7,328.00",
                        },
                        {
                            label: (
                                <LabelWithDetails
                                    label={t("implementationTotal")}
                                />
                            ),
                            value: "$1,500.00",
                        },
                    ]}
                />
                <Section
                    title={t("oneTimeChargesFees")}
                    items={[
                        { label: t("upfrontBalance"), value: "$0.00" },
                        { label: t("nonRefundableDeposit"), value: "$441.40" },
                    ]}
                />
                <Section
                    title={t("recurringChargesAndFees")}
                    items={[
                        {
                            label: t("monthlyInstallmentsTotal"),
                            value: "$441.40",
                        },
                        {
                            label: (
                                <LabelWithDetails
                                    label={t("monthlySubscriptionFeesTotal")}
                                />
                            ),
                            value: "$502.50",
                        },
                    ]}
                />
            </div>
            <div className="flex flex-col text-left gap-4">
                <HeadingText size="small">
                    {t("processingRatesAndFees")}
                </HeadingText>
                <Section
                    title={`${t("processingRates&Fees")} - ${t("flatPricing")}`}
                    items={[
                        {
                            label: (
                                <span className="break-all">
                                    {t("visaMastercardDiscoverAmericanExpress")}
                                </span>
                            ),
                            value: "3.84% / $0.00",
                        },
                    ]}
                />
                <Section
                    title={`${t("processingRates&Fees")} - ${t("dualPricing")}`}
                    items={[
                        {
                            label: t(
                                "changeInMenuPricingToOffsetProcessingFees",
                            ),
                            value: "4%",
                        },
                        {
                            label: t("discountWhenCustomerPaysWithCash"),
                            value: "3.84%",
                        },
                    ]}
                />
            </div>
        </div>
    );
};
