import { IProgressBlock } from "app";
import {
    EmployeeRange,
    FederalTaxType,
    IAddress,
    IApplicantStep,
    IBillingStep,
    IBusinessStep,
    IContact,
    IFasterFundingStep,
    ILineProduct,
    InstallationTimeframe,
    IOwnersStep,
    IOwnerType,
    IPlatformStep,
    IRatesType,
    IShippingStep,
    ISignatureStep,
    ITermsAndConditionsStep,
    LinkingType,
    OwnerStatus,
    SignatureSubmitStatus,
} from "features/application-wizard";
import { IProfileType } from "features/auth";
import { StepName, StepStatus } from "features/common";
import { IAppStateType, IConfigType } from "features/store";

export const defaultReducer = (state: IAppStateType) => ({ ...state });

export const defaultAddress: IAddress = {
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "US",
    street: "",
    zip: "",
};

const defaultContact: Omit<IContact, "businessName"> = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
};

export const defaultContactWithBusinessName: IContact = {
    ...defaultContact,
    businessName: "",
};

export function defaultRates(): IRatesType {
    return {
        groupCardsIcFlag: false,
        groupCardsCheckCard: 0,
        groupCardsInterchange: 0,
        groupCardsProcessing: 0,
        groupCardsQualified: 0,
        groupCardsMidQualified: 0,
        groupCardsNonQualified: 0,
        groupCardsTransactionFee: 0,
        groupCardsCnpRate: 0,
        groupCardsCnpTransactionFee: 0,
        amexIcFlag: false,
        amexInterchange: 0,
        amexProcessing: 0,
        amexQualified: 0,
        amexMidQualified: 0,
        amexNonQualified: 0,
        amexTransactionFee: 0,
        pinDebitIcFlag: false,
        pinDebitInterchange: 0,
        pinDebitProcessing: 0,
        pinDebitQualified: 0,
        pinDebitTransactionFee: 0,
        preferredPricing: true,
    };
}

export function defaultPlatformStep(): IPlatformStep {
    return {
        stepName: StepName.Quote,
        stepStatus: StepStatus.Incomplete,
        implementation: [] as ILineProduct[],
        other: [],
        software: [],
        hardware: [],
        shipping: [],
        upfrontTotal: 0,
        monthlyTotal: 0,
        hardwareSubtotal: 0,
        hasHardware: false,
        hasSoftware: false,
        hasImplementation: false,
        hasOther: false,
        implementationSubtotal: 0,
        otherSubtotal: 0,
        softwareSubtotal: 0,
        shippingSubtotal: 0,
        rates: defaultRates(),
    };
}

export function defaultBusinessStep(): IBusinessStep {
    return {
        stepName: StepName.Business,
        stepStatus: StepStatus.Incomplete,
        // business
        businessAddress: defaultAddress,
        businessContact: defaultContactWithBusinessName,
        dbaAddress: defaultAddress,
        dbaContact: defaultContactWithBusinessName,
        website: "",
        typeOfOwnership: undefined,
        businessType: undefined,
        businessOpenDate: "",
        federalTaxType: FederalTaxType.Ein,
        federalTaxId: "",
        federalTaxIdNotFormatted: "",
        exemptPurpose: undefined,
        serveInternationalRecipients: false,
        // employee
        employeeCount: EmployeeRange.Max5,
        // sales
        isAcceptingCards: true,
        isAmexUnder1Mil: true,
        requestedMonthlySales: undefined,
        averageTicket: undefined,
        requestedHighestTicket: undefined,
        isLegalSameAsDba: true,
        isIrsSameAsLegal: true,
        irsAddress: defaultAddress,
        irsName: "",
    };
}

export function defaultShippingStep(): IShippingStep {
    return {
        stepName: StepName.Shipping,
        stepStatus: StepStatus.Incomplete,
        shippingAddress: defaultAddress,
        isShippingSameAsBusiness: true,
        installationTimeframe: InstallationTimeframe.Immediately,
        installationContact: defaultContactWithBusinessName,
        additionalTaxes: [],
        salesTaxRate: undefined,
        suggestedTaxRate: undefined,
    };
}

export function defaultBillingStep(): IBillingStep {
    return {
        stepName: StepName.BillingSelector,
        stepStatus: StepStatus.Incomplete,
        account: undefined,
        billingSelector: {
            linkingType: LinkingType.Manual,
            [LinkingType.Manual]: {
                uploadedCheck: undefined,
            },
            [LinkingType.Plaid]: {
                linkToken: "",
                token: "",
            },
        },
        billingForm: {
            fields: {
                routingNumber: "",
                bankName: "",
                accountNumber: "",
                accountName: "",
            },
        },
    };
}

export function defaultProgressBlock(): IProgressBlock {
    return {
        view: {
            steps: [],
            activeStep: undefined,
        },
        navigation: {
            activeStepName: StepName.Business,
            latestStepName: StepName.Business,
            availableStepsNames: [],
            next: undefined,
            previous: undefined,
        },
    };
}

export function defaultOwner(): IOwnerType {
    return {
        id: -1,
        ssn: "",
        ssnNotFormatted: "",
        dob: "",
        contact: defaultContact,
        address: defaultAddress,
        title: "",
        ownershipPercentage: undefined,
        isOwner: true,
        isPrincipal: false,
        isResponsibleParty: true,
        isApplicant: false,
        isDeleted: false,
    };
}

export function defaultApplicantStep(): IApplicantStep {
    return {
        stepName: StepName.Applicant,
        stepStatus: StepStatus.Incomplete,
        ownerStatus: OwnerStatus.Owner,
        applicant: defaultOwner(),
    };
}

export function defaultOwnersStep(): IOwnersStep {
    return {
        stepName: StepName.Owners,
        stepStatus: StepStatus.Incomplete,
        owners: [],
    };
}
export function defaultConfig(): IConfigType {
    return {
        isConfigLoaded: false,
        opportunityType: undefined,
        opportunityId: undefined,
        isRestaurant: undefined,
        isPayments: undefined,
        businessName: "",
        isSubmitted: false,
        businessRebookAction: "",
        clickToSign: false,
        welcomeSetup: {
            exists: false,
            isEnabled: false,
        },
    };
}

export function defaultProfile(): IProfileType {
    return {
        email: "",
        firstName: "",
        lastName: "",
    };
}

export function defaultSignatureStep(): ISignatureStep {
    return {
        stepName: StepName.Signature,
        stepStatus: StepStatus.Incomplete,
        signUrl: "",
        signatureId: "",
        signatureRequestId: "",
        wasSigned: false,
        status: SignatureSubmitStatus.NotInitialized,
    };
}

export function defaultTermsAndConditionsStep(): ITermsAndConditionsStep {
    return {
        stepName: StepName.Signature,
        stepStatus: StepStatus.Incomplete,
        agreeToTermsAndConditions: false,
        trimmedDataURL: false,
        signerName: "",
        signTitle: "",
        signLegalName: "",
        signDate: "",
        tcHtml: "",
        tcVersion: "",
        signatureImage: "",
    };
}

export function defaultFasterFundingStep(): IFasterFundingStep {
    return {
        stepName: StepName.FasterFunding,
        stepStatus: StepStatus.Incomplete,
    };
}

// Default state of application,
// before any backend calls populate data
export function defaultState(): IAppStateType {
    return {
        config: defaultConfig(),
        progressBlock: defaultProgressBlock(),
        profile: defaultProfile(),
        platformStep: defaultPlatformStep(),
        businessStep: defaultBusinessStep(),
        shippingStep: defaultShippingStep(),
        billingStep: defaultBillingStep(),
        applicantStep: defaultApplicantStep(),
        ownersStep: defaultOwnersStep(),
        signatureStep: defaultSignatureStep(),
        termsAndConditionsStep: defaultTermsAndConditionsStep(),
        fasterFundingStep: defaultFasterFundingStep(),
    };
}
